<template lang="pug">
  v-container.custom-container
    v-row.text-center(align="center" justify="center")
      v-col.mb-2(cols="12")
        pages-heading.mb-2
          | Select the plan that best suit your business

        p Choose from any of our flexible subscription plan below and access content at via your account at anytime. Whether you are a small business or a large enterprise, we have the right solution to your content needs. Cancel anytime.

      template(v-if="!plans")
          v-col(cols="12" md="4")
            v-skeleton-loader.mx-auto(max-width="500" type="card")
          v-col(cols="12" md="4")
            v-skeleton-loader.mx-auto(max-width="500" type="card")
          v-col(cols="12" md="4")
            v-skeleton-loader.mx-auto(max-width="500" type="card")
      template
        v-row.plans-container
          v-col(cols="12" sm="6" md="4" v-if="plan.active" v-for="(plan, i) in plans")
            pages-plan-card(:plan="plan")
          v-col(cols="12" dark).custom_plan
            .display-2 Need a custom plan with access to more content?
            v-btn(outlined :color="'white'" to='/contact-us/plan').mt-3 Contact Us
          v-col(cols="12")
            pages-heading.my-2
              | On demand purchase whenever you need.
            p Use our Pay-as-you-go option to purchase a single piece of content on an as needed basis. Great for individuals and independent contractors.
          v-col(cols="12" md="6" v-for="(plan, i) in plansPayments")
            pages-plan-card(:plan="plan")
          v-expansion-panels
            h2.mt-10.mb-5 FAQs Customer
            v-expansion-panel(v-for="(item,i) in questionsBuyers" :key="i")
              v-expansion-panel-header {{item.question}}
              v-expansion-panel-content {{item.answer}}
          v-col(cols="12" dark).my-3.custom_plan.custom_plan--blue
            .display-2 Undecided? Let us help you with your decision.
            v-btn(outlined :color="'white'" to='/contact-us/plan').mt-3 Contact Us
      // template(v-for="(plan, i) in plans")
        // v-col(:key="i" cols="12" sm="6" md="3")
          pages-plan-card(:plan="plan")
        // v-col(cols="3")
          v-card.v-card--plan.mx-auto.pt-3.pb-4.px-2.text-center(max-width="100%" dark v-if="i + 1 === plans.length")
            p jajajaja
        // v-col.pa-6(v-if="(i === 1 && $vuetify.breakpoint.smAndDown) && i + 1 !== plans.length" :key="`divider-${i}`" cols="12")
          v-divider
</template>

<script>
  // import axios from 'axios'
  import loaders from '@/plugins/mixins/loaders'
  export default {
    name: 'Plans',
    components: {
      PagesHeading: () => import('@/views/pages/component/Heading'),
      PagesPlanCard: () => import('@/views/pages/component/PlanCard'),
    },
    mixins: [loaders],

    data: () => ({
      questionsBuyers: [
        {
          question: 'How do I locate the footage that I am looking for?',
          answer: 'You would click search and enter some basis parameters such as date the accident, incident or event occurred, street location, city and state, approximate time or as much information that you have in order to narrow your search. You also want to do multiple search by adding and remove some fields to ensure you’re getting the correct response.',
        },
        {
          question: 'How can I be sure that I am purchasing the correct footage?',
          answer: 'We offer a thumbnail of the image or a 3 second video footage that the customer can review to confirm its the correct evidence prior to purchase.',
        },
        {
          question: 'What is the refund process?',
          answer: 'We do not offer refunds, since we offer the thumbnail prior to purchase, we ask that you review the details prior to purchasing since its non-refundable.',
        },
        {
          question: 'Will the contributor be informed about who purchased the footage?',
          answer: 'No, neither the contributor nor the customer’s information is shared, both party’s details are anonymous. ',
        },
        {
          question: 'If the footage that was purchased is not visible what are my options?',
          answer: 'Since the video is reviewed prior to being uploaded on the site, you can send us an email notifying us of the error, we will review and resend the footage. ',
        },
        {
          question: 'What are your payment options?',
          answer: 'You can purchase the footage as a single one time purchase or you can setup a monthly subscription at a discounted rate.',
        },
        {
          question: 'What is the cost of a single purchase?',
          answer: 'For a 1x purchase of a video footage the cost is $149.95 and for image its $99.95',
        },
        {
          question: 'Why is the charge for the video content more than the pictures?',
          answer: 'Video content are easier to prove liability than pictures.',
        },
        {
          question: 'What is the cost of the monthly subscription purchase?',
          answer: 'For subscription fee breakdown see subscription and discount offers.',
        },
        {
          question: ' After I purchase the video how do I receive the content?',
          answer: 'You have the option to view and/or upload the content. The content will also stay in your account for up to 1 year, after that it maybe archived. So you can log back into your account if you need to share it after your purchase is completed.',
        },
        {
          question: 'Can I get the details of the contributor to obtain a witness statement?',
          answer: 'No, all party’s information is anonymous.',
        },
        {
          question: 'Why charge a fee for the evidence?',
          answer: 'Contributors are more likely to upload the content if they anticipate being paid, so part of charging the fee is to encourage contributors to quickly upload the footage so they can be refunded.',
        },
        {
          question: 'If I upload the video footage but did not receive the content, what do I do?',
          answer: 'You can access your account that will have the content you purchased and you can them resent the content to yourself. If you are still having an issue, please email us and we will be happy to assist you.',
        },
        {
          question: 'What method of payment can I use to purchase the content?',
          answer: 'You can use a variety of payment method to purchase the content such as visa, master card, American express, discover and debit card.',
        },
        {
          question: 'Who do I contact if I have an issue with my account or purchase?',
          answer: 'Send an email to https://scenefinder.com/customerservice and provide a description of your concern.',
        },
        {
          question: 'How does the subscription plan work and can I cancel my subscription?',
          answer: 'If you select any of our subscription service, the plan renews every month. You can cancel your subscription at any time during your plan term.',
        },

      ],
      plansPayments: [
        { nickname: 'A SINGLE IMAGE', amount: 9995 },
        { nickname: 'A SINGLE VIDEO', amount: 14995 },
      ],
    }),

    computed: {
      plans () {
        return this.$store.getters['subscriptions/getPlans']
      },
    },

    created () {
      const vm = this
      vm.loadPlans()
    },
  }
</script>

<style scoped lang="scss">
  .plans-container{
    display: flex;
    align-items: flex-start;
  }
  .custom_plan {
    &--blue{
      background: #00537d;
    }
    height: 208px;
    background: #b2262d;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>
